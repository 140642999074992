import type { NavLinkProps } from '@remix-run/react'
import { NavLink, useNavigation } from '@remix-run/react'
import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { cn } from '~/styles/utils'
import { buttonVariants } from '../ui/button'
import { Icon } from '../ui/icon'
import { ScrollArea } from '../ui/scroll-area'
import { sidebarOpenAtom } from './layout'

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> { }

export function Sidebar({ className }: SidebarProps) {
  const navigation = useNavigation()
  const setOpenSidebar = useSetAtom(sidebarOpenAtom)

  const navlinkClassName: NavLinkProps['className'] = ({
    isActive,
    isPending,
    isTransitioning,
  }) => {
    return cn(
      buttonVariants({
        variant: isActive ? 'secondary' : 'ghost',
        size: 'default',
        className: '',
      }),
      'w-full justify-start rounded-none border-b border-gray-200',
      isActive
        ? 'bg-sky-200 hover:bg-sky-600 hover:text-sky-100 text-sky-900'
        : '',
      '[&[data-disabled]]:opacity-40',
    )
  }

  useEffect(() => {
    if (navigation.state != 'idle') {
      setOpenSidebar(false)
    }
  }, [navigation.state, setOpenSidebar])

  return (
    <ScrollArea className={cn('pb-4', className)}>
      <div className="space-y-4 py-4">
        <div className="px-3 py-2">
          <div className="space-y-1">
            <NavLink to="/kasa" className={navlinkClassName}>
              <Icon name="fa6-solid:turkish-lira-sign" className="mr-2 size-4" />
              Kasa
            </NavLink>
            <NavLink to="/veresiye" className={navlinkClassName}>
              <Icon name="fluent:wallet-credit-card-32-regular" className="mr-2 size-4" />
              Veresiye
            </NavLink>
            <NavLink to="/kasa-veresiye-musteri" className={navlinkClassName}>
              <Icon name="ph:users-four-light" className="mr-2 size-4" />
              Kasa Veresiye Müşteri
            </NavLink>
            <NavLink to="/domain" className={navlinkClassName}>
              <Icon name="material-symbols:cloud-domains" className="mr-2 size-4" />
              Domain
            </NavLink>
            <NavLink to="/hosting" className={navlinkClassName}>
              <Icon name="carbon:ibm-cloud-direct-link-1-dedicated-hosting" className="mr-2 size-4" />
              Hosting
            </NavLink>
            <NavLink to="/domain-hosting-musteri" className={navlinkClassName}>
              <Icon name="ph:users-four-light" className="mr-2 size-4" />
              Domain Hosting Müşteri
            </NavLink>
            <NavLink to="/rapor" className={navlinkClassName}>
              <Icon name="iconoir:reports" className="mr-2 size-4" />
              Rapor
            </NavLink>
          </div>
        </div>
      </div>
    </ScrollArea>
  )
}
